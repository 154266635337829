import React from 'react';
import Navigation from "../../components/Navigation";
import GalleryCarousel from "./galleryCarousel";
import Footer from "../../components/Footer";
// import UpcomingEvents from "./upcomingEvents";
import BlogSection from "../home/BlogSection";
const GalleryAndEvents = () => {
    return (
        <div>
            <div className="bg-gradient">

                <Navigation/>
            </div>
            <div className="center-all">
                <h4 className="h4 mgB2 mgT4">Gallery & Events</h4>
                 <GalleryCarousel/>

                {/*<UpcomingEvents/>*/}
            </div>
            <div className="mgT2">

                <BlogSection count={3}/>
            </div>
            <Footer/>
        </div>
    );
};

export default GalleryAndEvents;