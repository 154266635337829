import React from 'react';
import {Link} from "react-router-dom";
import r1 from'../../assets/mental_health.jpg'
import ScrollAnimation from "../../hoc/ScrollAnimation";
const HealthCare = () => {
    return (
       <ScrollAnimation>
           <div className={'how-section'}>
               <div className="grid- center-all align-center">
                   <div className="grid-item2_4">
                       <div className="stagger-imgs">
                           {/*<div className="stagger-imgs-1">*/}
                           {/*    <img src={r1} className={'fluidImg'} alt=""/>*/}
                           {/*</div>*/}
                           <div className="stagger-imgs-item">
                               <img src={r1} className={'fluidImg'} alt="by rfstudio on pexels" title={'by rfstudio on pexels'} />
                           </div>
                       </div>
                   </div>
                   <div className="grid-item2_4 mgL2max">
                       <h4 className="h3  ">Science, Research and Data to solve the urgent Mental Health Challenges in Africa</h4>
                       <p className="h5 mgT2  ">Together, let's erase the stigma and foster mental wellness. Explore data-driven solutions for effective mental health support.</p>
                        <p className="h5 mgT2">We are researching to understand and drive a transformative change in the ability to intervene as early as possible in the cause of anxiety, depression and psychosis defined by the people who experience them.
                        </p>
                        <Link to={'/contact'} className="btn btn-primary mgT8">Reach out</Link>
                   </div>
               </div>
           </div>
       </ScrollAnimation>
    );
};

export default HealthCare;