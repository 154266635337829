import React from 'react';

import ejiro from'../../assets/ejiro.png'
import omeh from'../../assets/avatar.JPG'
import dare from'../../assets/Mr Dare.png'
import lanre from'../../assets/mr lanre.jpg'
import onuigbo from'../../assets/onuigbo.jpg'
import honourable from'../../assets/rot_1.jpg'
import jide from'../../assets/mr_jide.jpg'
const TeamSection = () => {
    return (
        <div className="team-section">
            <div className="center-all">
                <h5 className="h4 centerText  ">OUR TEAM</h5>
                <div className="mgT4 team-section-grid">
                    <div className="team-section-grid-item textCenter">
                        <img alt={''} src={onuigbo} className={'circle-avatar'}/>
                        <h6 className="h6 blueText mgT4">Honourable Chief Sam Onuigbo</h6>
                        <p className="h7">Member Board of Trustees</p>
                        <p className="h7 greyText mgT1">Vice President Africa Climate Change Assembly</p>
                    </div>
                    <div className="team-section-grid-item textCenter">
                        <img alt={''} src={honourable} className={'circle-avatar'}/>
                        <h6 className="h6 blueText mgT4">Honourable Rotimi Agunsoye,</h6>
                        <p className="h7">Chairman Board of Trustees</p>
                        <p className="h7 greyText mgT1">Chairman, house committee on sustainable development goals </p>
                    </div>
                    <div className="team-section-grid-item textCenter">
                        <img alt={''} src={lanre} className={'circle-avatar'}/>
                        <h6 className="h6 blueText mgT4"> Lanre Bello</h6>
                        <p className="h7 ">Member Board of Trustees; Information Security Consultant.</p>
                    </div>
                    <div className="team-section-grid-item textCenter">
                        <img alt={''} src={dare} className={'circle-avatar'}/>
                        <h6 className="h6 blueText mgT4">Dare Dele</h6>
                        <p className="h7 ">Director of Finance & Account</p>
                        <p className="h7 greyText">Chattered Accountant</p>
                    </div>
                    <div className="team-section-grid-item textCenter">
                        <img alt={''} src={jide} className={'circle-avatar'}/>
                        <h6 className="h6 blueText mgT4">Jide Solanke</h6>
                        <p className="h7 mgT2">Chief Legal Advisor & Company Secretary</p>
                        {/*<p className="h7 greyText ">Lawyer</p>*/}
                    </div>
                    <div className="team-section-grid-item textCenter">
                        <img alt={''} src={ejiro} className={'circle-avatar'}/>
                        <h6 className="h6 blueText mgT4">Ejiro-Oghene Paul</h6>
                        <p className="h7 ">Head of Projects</p>
                        <p className="h7 greyText ">Project leader for healthcare and climate change initiatives.</p>
                    </div>

                    <div className="team-section-grid-item textCenter">
                        <img alt={''} src={omeh} className={'circle-avatar'}/>
                        <h6 className="h6 blueText mgT4">Omeh S. Okechukwu</h6>
                        <p className="h7 ">Head of IT</p>
                        <p className="h7 greyText ">Software Engineer</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamSection;