import React, {useCallback, useContext, useEffect, useState} from 'react';
import AdminDash from "../../layout/admin";
import Table from "../../components/Table";
import AxiosInstance from "../../helpers/axiosInstance";
import {UserProvider} from "../../hoc/userProvider";
import {FiSearch} from "react-icons/fi";
import Modal from "../../components/Modal";
import {toast,ToastContainer} from "react-toastify";
import Moment from "react-moment";

const FullGallery = () => {
    const baseUrl = "http://localhost:5000"
    const [posts,setPosts] = useState([])
    const [offset,setOffset] = useState(1)
    const [limit,setLimit] = useState(10)
    const [search,setSearch] = useState('')
    const [showEdit,setShowEdit] = useState(false);
    const [showDelete,setShowDelete] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [selectedPost,setSelectedPost] = useState({});

    const [numOfPages,setNumOfPages] = useState(1)
    const user = useContext(UserProvider) || localStorage.getItem('user_id');
    function _createPagination() {
        let _list = [];
        for (let i=1;i<=numOfPages;i++){
            _list.push(<span onClick={()=>getPg(i)} className={offset===i?'h6 pg pg-active':'h7 pg'} key={i}>{i}</span>)
        }
        return _list;
    }

    function getPg(pg){
        setOffset(pg);
    }
    function prev() {
        if (offset>1){
            setOffset(offset - 1)
        }
    }
    function nextPage() {
        setOffset(offset + 1)
    }
    function selectPost(id) {
        const index = posts.findIndex(n=>n._id===id);
        setSelectedPost(posts[index]);
    }
    const  getAllGallery = useCallback(() =>{
        AxiosInstance.get(`/g/get/all?offset=${offset}&limit=${limit}`,{headers:{
                authorization: localStorage.getItem('user_ton')
            }})
            .then(res=>{
                console.log(res.data.data.docs)
                setPosts(res.data.data.docs)
                setNumOfPages(res.data.data.totalPages)
            })
            .catch(err=>{
                console.log(err)
            })
    },[limit,offset,setNumOfPages,setPosts]);

    function deletePost() {
        AxiosInstance.delete(`/g/${selectedPost._id}/del`,{
            headers:{
                authorization: user.token
            }
        })
            .then(res=>{
                toast.info(res.data.msg);
                setTimeout(()=>{
                    setShowDelete(false)
                    setRefresh(!refresh)
                },1500)
            })
            .catch(err=>{
                toast.error(err.response.data.msg)
            })
    }

    useEffect(()=>{
        getAllGallery();
    },[getAllGallery,refresh])
    return (
        <AdminDash>
            <h4 className="h4 whiteText">All Events & Gallery</h4>
            {/*<p className="h6 lightColor">View all Gallery</p>*/}
            <div className="mgT4  post-box">
                <form className={'display_flex'}>
                    <div className="form-input-box " style={{width:'100%',display:'flex'}}>
                        <input value={search} onChange={(e)=>setSearch(e.target.value)} className="form-input" placeholder={'Search posts by name'}/>
                        <button style={{border:'none',backgroundColor:'inherit'}}><FiSearch size={24} className={'whiteText'}/></button>
                    </div>

                    <div className="display_flex mgL2">
                        <div className="form-input-box ">

                            <select className={'form-select'} value={limit} onChange={(e)=>setLimit(e.target.value)}>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                    </div>
                </form>

                {
                    showDelete?  <Modal closeModal={setShowDelete}>
                        <ToastContainer/>
                        <h5 className="h6 whiteText">Are You Sure You want to delete Post?</h5>

                        <button className="btn btn-red mgT4" onClick={deletePost}>Delete</button>


                    </Modal>:null
                }
                {
                    showEdit?  <Modal closeModal={setShowEdit}>
                        <h6 className="h6 whiteText">Edit Post</h6>
                              </Modal>:null
                }
                <Table type={'dark-table'} headers={['Title','Description','Image','Start Date','End Date','']} body={
                    <>
                        {
                            posts.length>0? posts.map((p)=><tr className={'h7'} key={p._id}>
                                <td>{p.title}</td>
                                <td >{p.description}</td>
                                <td>{p?.files.length>0?p.files.map((f,i)=>{
                                    if (p.type==='video'){
                                       return <video key={i} className={'fluidImg'} src={`${baseUrl}/${f}`} controls autoPlay/>
                                    }
                                    else if (p.type==='image'){
                                        return <img key={i} className={'fluidImg'} alt={p.title} src={`${baseUrl}/${f}`} />

                                    }else {
                                        return <p key={i}>No file</p>
                                    }
                                }):null}</td>
                                {p?.start? <React.Fragment>
                                <td>
                                        {/*<span className="h6">*/}
                                           <Moment format={"MMMM DD YYYY"}>
                                               {
                                               p?.start
                                           }
                                           </Moment>
                                        {/*</span>*/}
                                </td>
                                {p?.end?
                                <td>

                                      <span className="h6">
                                           <Moment format={"MMMM DD YYYY"}>
                                               {
                                               p?.end
                                           }

                                </Moment>
                                        </span>

                                </td>:null}
                                </React.Fragment>:null}
                                 <td>
                                    <span  className="btn btn-red"  onClick={()=>{
                                        selectPost(p._id);
                                        setShowDelete(true);
                                    }}>Delete</span>
                                    {/*<span className="btn btn-dark">Suspend</span>*/}

                                </td>
                            </tr>):<tr className={'emptyTable'} ><h6 className="h6"><td colSpan={4} className={'tr-nodata'}>NO Data Found</td></h6></tr>
                        }
                    </>
                }/>
            </div>
            <div className="paginator">
                <div className={'mgV2'}>
                    <span className="h6 pg-active-tab" onClick={prev}>Prev</span>{
                    numOfPages!==1?_createPagination():<span className={'h7 pg-active pg'} >1</span>
                }  <span className="h6 pg-active-tab" onClick={nextPage}>NEXT</span>
                </div>
            </div>

        </AdminDash>
    );
};

export default FullGallery;