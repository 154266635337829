import React, {useCallback, useEffect, useState} from 'react';
import Navigation from "../../components/Navigation";
import {AnimatePresence,motion} from "framer-motion";
import Moment from "react-moment";
import Modal from "../../components/Modal";
// import img from '../../assets/research_.png'
// import img2 from '../../assets/research.png'
import AxiosInstance from "../../helpers/axiosInstance";
import {toast} from "react-toastify";
import Footer from "../../components/Footer";
const EventPage = () => {
    // const base = ''
    const [events,setEvents] = useState([
        {
            title:"",
            files:[

            ],
            createdAt:Date.now(),
            description:"",
            _id: ""
        }
    ])


    const getAllEvents = useCallback(()=>{
    AxiosInstance.get('/g/get/all')
    .then(res=>{
        setEvents(res.data.data.docs)
    })
        .catch(err=>{
            toast.error(err.message)
        })
    },[])
    const [showImage,setShowImage] = useState(false)
    const [selectedId,setSelectedId] = useState(null)
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    const container = {
        hidden:{
            opacity:1,scale:0
        },
        visible:{
            opacity:1,
            duration: 1,
            scale:1,
            transition:{
                delayChildren:0.3,
                staggerChildren:0.2
            }
        }
    }
    const item = {
        hidden:{
            y:20,opacity:0
        },
        visible:{
            y:0,
            opacity:1
        }
    }
    const [current,setCurrent] = useState({
        title:"",
        description:'',
        createdAt:''
    })
    function toggleImg(img,im,i){
            setShowImage(true);
            setSelectedId(im._id + i);
            setCurrent(im)
    }

    useEffect(()=>{
        getAllEvents();
    },[getAllEvents])
    return (
        <div>
            <Navigation className={'navigation-light'}/>
            <div className="center-all">
                <AnimatePresence>

                    {
                        showImage? <Modal body={'width100'} extra={selectedId} closeModal={setShowImage}>
                            <motion.div transition={transition} layoutId={selectedId}>
                                <div className="" style={{height:'40rem',
                                    overflow: "hidden"}}>

                                    <motion.img  alt={''} className={'object-contain '} src={`http://localhost:5000/${current?.files[0]}`}/>
                                </div>
                                <motion.h2 className="h5 display_block mgT2">{current?.title}</motion.h2>
                                <motion.p className="h6 mgT2 display_block">{current?.description}</motion.p>
                                <motion.p className={'greyText h7 mgT2'}>
                                    <Moment format={"MMM DD, YYYY"}>{current?.createdAt}</Moment>
                                </motion.p>

                            </motion.div>
                        </Modal>:null
                    }

                </AnimatePresence>
            </div>
           <div className="center-all mgT4">
               <h2 className="h4 ">Events </h2>
               <motion.div className="mgT4 gallery_img_box"  variants={container}
                           initial={'hidden'}
                           animate={'visible'}>
                   {
                       events.length>0?events.map((im,i)=>  <motion.div variants={item} layoutId={im._id + i}  key={im._id + i } onClick={()=>toggleImg(im.files[1],im,i)} className="gallery_img_box-item" >
                                   <motion.img src={`http://localhost:5000${im.files[0]}`} alt="" className={'fluidImg'}/>
                                   <motion.div className="gallery_img_box-item-desc">
                                       <motion.h6 className="h5 whiteText ">{im.title}</motion.h6>
                                       <motion.h6 className="h6 whiteText  mgT1">{im?.description.slice(0,50)}</motion.h6>
                                       <motion.h6 className="h7 mgT2 whiteText"><Moment format={"MMM DD, YYYY"}>{im?.createdAt}</Moment></motion.h6>
                                   </motion.div>

                               </motion.div>):null
                   }

               </motion.div>
           </div>
            <Footer/>
        </div>
    );
};

export default EventPage;