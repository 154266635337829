import React,{useState,useCallback} from "react"
import './sass/main.scss';

import 'react-toastify/dist/ReactToastify.css';
import {UserProvider} from "./hoc/userProvider";
import {useNavigate} from 'react-router-dom'
import {Routes,Route} from 'react-router-dom'
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Research from "./pages/research/research";
import GalleryAndEvents from "./pages/gallery/galleryAndEvents";
import EventPage from "./pages/gallery/eventPage";
import ProtectedRoute from "./hoc/protectedRoute";
import Users from "./pages/blog/admin/users";
import AllPosts from "./pages/blog/allPosts";
import UploadToGallery from "./pages/blog/admin/uploadToGallery";
import FullGallery from "./pages/blog/fullGallery";
import IndexPage from "./pages/blog/admin/indexPage";
import AddArticle from "./pages/blog/admin/addArticle";
import Article from "./pages/blog/articles/article";
import BlogHome from "./pages/blog/blogHome";
import Login from "./pages/blog/auth/login";
import Register from "./pages/blog/auth/register";
import Researchers from "./pages/researchers/researchers";
function App() {
  const navigate = useNavigate();
  const [user,setUser] = useState(localStorage.getItem('user_id')||{});
  const [token,setToken] = useState(localStorage.getItem('user_ton')||null);
  const [role] = useState(localStorage.getItem('tr_rrl') || null);
  // const {isOnline} = IsOnline();
  const logOut = useCallback (()=> {
    localStorage.clear();
    navigate('/',{replace:true});
  },[navigate])
    function login(data,token) {
        setUser(data);
        setToken(token);
        localStorage.setItem('user_ton',token)
        localStorage.setItem('user_id',JSON.stringify(data));
        //navigate to dashboard
        navigate('/blog/admin',{replace:true});
    }
  return (
      <UserProvider.Provider value={{token,user,logOut,login,role}}>
        <Routes>
           <Route path={'/'} element={<Home/>}/>


            <Route path={'/register'} element={<Register/>}/>
            <Route path={'/login'} element={<Login/>}/>
           <Route path={'/gallery-and-events'} element={<GalleryAndEvents/>}/>
           <Route path={'/event/:id'} element={<EventPage/>}/>
           <Route path={'/about'} element={<About/>}/>
           <Route path={'/contact'} element={<Contact/>}/>
           <Route path={'/research'} element={<Research/>}/>
           <Route path={'/researchers'} element={<Researchers/>}/>
           <Route path={'/events'} element={<EventPage/>}/>

        {/*    AUTH ROUTES*/}


            <Route path={'/news-and-reports'} element={<BlogHome/>}/>
            {/*ADMIN ROUTES*/}
            <Route path={'/news-and-reports/:id'} element={<Article/>}/>
            <Route path={'/blog/add'} element={<ProtectedRoute><AddArticle/></ProtectedRoute>}/>
            <Route path={'/blog/admin/'} element={<ProtectedRoute><IndexPage/></ProtectedRoute>}/>
            <Route path={'/blog/gallery/'} element={<ProtectedRoute><FullGallery/></ProtectedRoute>}/>
            <Route path={'/blog/upload/'} element={<ProtectedRoute><UploadToGallery/></ProtectedRoute>}/>
            <Route path={'/blog/allPosts/'} element={<ProtectedRoute><AllPosts/></ProtectedRoute>}/>
            <Route path={'/blog/allUsers/'} element={<ProtectedRoute><Users/></ProtectedRoute>}/>
            <Route path={'/*'} element={<>Error</>}/>
        </Routes>
      </UserProvider.Provider>
  );
}

export default App;
