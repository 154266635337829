import React from 'react';
import Hero from "./hero";
// import Partners from "./partners";

import img1 from "../../assets/DSC_7001.png"
import img2 from "../../assets/DSC_7021.png"
import img3 from "../../assets/DSC_7068.png"
import Footer from "../../components/Footer";
import HowSection from "./howSection";
import MissionVision from "../about/missionVision";
import AboutSummary from "./AboutSummary";
import BlogSection from "./BlogSection";
import HealthCare from "./healthCare";
import GlobalWarming from "./globalWarming";
import ScrollAnimation from "../../hoc/ScrollAnimation";
// import JoinUs from "../contact/joinUs";
const Home = () => {
    return (
        <div>

            <Hero/>
            <AboutSummary/>
            <MissionVision/>
            <HowSection />
            <HealthCare/>
            <GlobalWarming/>
            <ScrollAnimation>
                <div className="">

                    <div className={'grid-box center-all '}>
                        <div className="grid-box-item">
                            <img src={img1} className={'fluidImg'} alt=""/>
                        </div>
                        <div className="grid-box-item">
                            <img src={img3} className={'fluidImg'} alt=""/>
                        </div>
                        <div className="grid-box-item">
                            <img src={img2} className={'fluidImg'} alt=""/>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
            <div style={{paddingTop:"6rem"}}>
                {/*<JoinUs/>*/}
            </div>
            <BlogSection count={3}/>
            <Footer/>
        </div>
    );
};

export default Home;