import React from 'react';
import ScrollAnimation from "../../hoc/ScrollAnimation";

const ResearcherCard = ({data}) => {
    return (
       <ScrollAnimation>
           <div className={'researcherCard grid'}>
               <div className="grid-item1_4">
                   <h6 className="h5">{data?.name}</h6>
                   <span className={'h6 display_block mgV2'}>{data?.title}</span>
                   {data?.img? <img src={data?.img} className={'fluidImg'} alt=""/>:null}
               </div>
               <div className="grid-item3_4">
                   {
                       data.desc
                   }
               </div>
           </div>
       </ScrollAnimation>
    );
};

export default ResearcherCard;