import React from 'react';

import Logo from '../assets/logo.png'
// import {BiHeadphone} from "react-icons/bi";
// import {FiTwitter} from "react-icons/fi";
import { FaLinkedinIn, } from "react-icons/fa";
import {Link} from "react-router-dom";
const Footer = () => {

    return (
        <div className={'footer mgT8'}>
           <div className="center-all">

               <div className="mgT2 grid- just-btw align-center ">
                   <div className={'grid-item1_4'}>

                       <img className={'logo'} alt={'logo'} src={Logo}/>

                   </div>
                   <ul className={'grid-item2_4 align-center h-list display_flex'}>
                       <li className={'greyText mgL2'}>
                           <Link to={'/'}>Home</Link>
                       </li>
                       <li className={'greyText mgL2'}>
                          <Link to={'/about'}> About</Link>
                       </li>
                       <li className={'greyText mgL2'}>
                           <Link to={'/news-and-reports'}>News</Link>
                       </li>
                   </ul>
                   <div className="display_flex align-center">

                       <p className="h7 whiteText">climateandhealthcare@ccshdi.com</p>
                       <a className={' mgL2'} href={'https://www.linkedin.com/company/climate-change-and-sustainable-healthcare'} rel={'noreferrer'}>

                       <FaLinkedinIn  className={'icon'}/>
                   </a>
                   </div>
               </div>

               <hr className="hr"/>
               {/*<p className="h7 whiteText">All texts and content on this website are the proprietary property of CCSHDI. Any unauthorized use, reproduction, or distribution of the content is strictly prohibited and may be subject to legal action.</p>*/}
               <p className="h7 mgV2 whiteText textCenter">
                   CCSHDI is a registered organization, with registration number RC 6960727. The organization operates in compliance with the relevant laws and regulations governing non-profit entities in Nigeria.</p>
               {/*<p className={'h7 mgV2 whiteText'}>*/}
               <p className="h7 mgV2 whiteText textCenter">ccshdi.com &copy; 2023</p>

           </div>
        </div>
    );
};

export default Footer;