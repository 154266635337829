import React from 'react';
// import bg from '../../assets/mission_bg.svg'
// import purpose from '../../assets/purpose.png'
// import mission from '../../assets/mission.png'
import ScrollAnimation from "../../hoc/ScrollAnimation";
const MissionVision = () => {

    return (
      <ScrollAnimation>
          <div className={'mission-section'}>
              <div className=" center-all" >
                  <div className="grid" >
                      <div className="grid-item2_4 textCenter">
                          <h6 className="h4 stylishHeaders">Our Vision</h6>
                          {/*<img src={purpose} className={'mgV2'} style={{width:"12rem"}} alt={'Mission icons created by ultimatearm - Flaticon'}/>*/}
                          <p className="h5  mgT2">To provide research and data driven innovation to influence climate change and health</p>
                      </div>
                      <div className="grid-item2_4 textCenter">
                          <h6 className="h4 stylishHeaders">Our Mission</h6>
                          {/*<img src={mission} className={'mgV2'} style={{width:"12rem"}} alt={'Mission icons created by ultimatearm - Flaticon'}/>*/}
                          <p className="h5  mgT2">A reputable advocacy and research driven organisation providing cutting edge data driven and inclusive solutions through collaboration to climate change and health challenges</p>
                      </div>
                  </div>
              </div>
          </div>
      </ScrollAnimation>
    );
};

export default MissionVision;