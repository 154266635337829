import React from 'react';
import {Link} from "react-router-dom";
import r1 from'../../assets/DSC_7021.png'
import ScrollAnimation from "../../hoc/ScrollAnimation";
const HowSection = () => {

    return (
        <ScrollAnimation>
            <div className={'how-section'}>
                <div className="grid- align-center center-all">
                    <div className="grid-item2_4">
                        <h4 className="h3  ">How we are making a difference</h4>
                        <ul className={'list-ul'}>
                            <li className="h5 mgT2 ">We work to promote a better understanding of climate change and mental health issues, and develop evidence-based strategies to mitigate the impact of both.
                            </li>
                            <li className="h5 mgT2 " style={{fontWeight:"normal"}}>We are dedicated to advancing the field of mental health research, with a focus on developing evidence-based interventions for the prevention and treatment of mental health disorders.</li>

                            <li className="h5 mgT2 " style={{fontWeight:"normal"}}>The effects of climate change on ecosystems, natural resources, and human health are the subject of ground-breaking research being conducted by our team of experts. In order to alleviate the effects of climate change on communities around the world, we are trying to understand the intricate relationships between climate change and human well-being.</li>
                        </ul>

                        <Link to={'/about'} className="btn btn-primary mgT2">Learn More</Link>
                    </div>
                    <div className="grid-item2_4 mgL2max">
                        <div className="stagger-imgs">
                            <div className="stagger-imgs-item">
                                <img src={r1} className={'fluidImg'} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollAnimation>
    );
};

export default HowSection;