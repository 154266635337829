import axios from 'axios';
// const token = localStorage.getItem('user_tk');
// console.log(token)
const AxiosInstance = axios.create({
  baseURL:'/api/v1',
  // baseURL:'http://localhost:5000/api/v1',
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
    function(config) {
      config.headers['authorization'] = localStorage.getItem('user_ton');
      return config;
    },
    function(error) {
      // Handle request errors
      return Promise.reject(error);
    }
);
export default AxiosInstance;


