import React, {useState} from 'react';
import tolu from'../../assets/dr tolu.png'
import lajide from'../../assets/dr_lajide.jpg'
import abdul from'../../assets/dr_abdul.jpg'
const useResearchers = () => {
    const [researchers] = useState([
        // {
        //     name:'Dr. Lajide Dayo ',
        //     title:' Research Director CCSHDI',
        //     img:lajide,
        //     desc:<div>
        //         <p className={'h5 mgT2'}>Dr. Dayo Lajide is a highly experienced public health professional with a strong background in healthcare program development, management, research and  evaluation.
        //             With a Master's degree in Public Health, a Bachelor's degree in Dental Surgery and over 25 years International non-governmental  and governmental work experience, Dr. Lajide brings a comprehensive understanding of the healthcare landscape to her role as our research director. Driven by a passion for strengthening health systems, Dr. Lajide has successfully led grant management initiatives and coordinated the implementation of strategic projects for the Global Fund and the World bank.
        //             With expertise in research planning, statistical analysis, and monitoring, Dr. Lajide has contributed to evidence-based decision-making and the improvement of healthcare delivery. Dr. Lajide's exceptional leadership and coordination skills, along with a proven ability to establish strong relationships with stakeholders, makes her our star Research Director in CCSHDI.</p>
        //     </div>
        // },
        {
            name:'Dr. Tolu Ajomale ',
            title:'Asst. Research Director CCSHDI',
            img: tolu,
            desc:<div>
                <p className={'h5 mgT2'}>Dr. Tolulope Ajomale, a visionary Mental Health Researcher, who combines a wealth of expertise in public health and an unwavering commitment to advancing healthcare practices. As the Assistant Director of research at CCSHDI, he joins the team with an extensive experience in mental health care interventions and research. </p>

                <p className={'h5 mgT2'}>Dr. Ajomale also continue to spearheads groundbreaking initiatives that redefine the landscape of mental healthcare delivery across Nigeria. His illustrious career encompasses pivotal roles such as Research Program Lead for Climate Change Awareness & Sustainable Healthcare Development and Pillar Lead for Mental Health & Psychosocial Support during the pandemic. By leveraging a profound understanding of community-based services, Dr. Ajomale's outstanding contributions culminated in the development and implementation of the pioneering Lagos State Mental Health Law which is the first of its kind in Nigeria. A respected clinician and scholar, Dr. Ajomale's relentless pursuit of evidence-based policies and innovative solutions that would shape the future of mental health research and have profound impact on individual well-being continues to guide his work. </p>
            </div>
        },
        {
            name:'Dr Charles Umeh',
            title:'Mental Health Research Team Member',
            desc:<div>
                <p className={'h5 mgT2'}>Dr. Charles Umeh is a dedicated researcher with expertise in the field of Clinical and Health Psychology. With a strong academic background and extensive experience, Dr. Umeh has made significant contributions to the field through his research and professional work. Currently serving as a researcher at CCSHDI, Dr. Umeh plays a pivotal role in advancing healthcare practices and improving the well-being of individuals.</p>

                <p className={'h5 mgT2'}>Throughout his career, Dr. Umeh has actively participated in various research projects and conferences, consistently broadening his understanding of clinical and health psychology. His areas of expertise encompass a wide range of subjects, including Clinical interventions, mental health (Adolescent and Young adults), HIV/AIDS Adherence Counseling, and Global mental health amongst others. Dr. Umeh's dedication to his field has been recognized through numerous awards, and publications of research papers in renowned International Journals of mental health.</p>
                <p className={'h5 mgT2'}>
                    As a researcher at CCSHDI, Dr. Umeh collaborates with a multidisciplinary team to conduct cutting-edge research focused on healthcare research and developmental interventions. His work contributes to the development of evidence-based practices, striving to improve the quality of care and overall well-being of individuals. Dr. Umeh's expertise in clinical and health psychology, combined with his strong research acumen, allows him to make significant contributions to the field and positively impact the lives of those he serves.</p>
            </div>
        },
        {
            name:'Dr Jubril Abdulmalik',
            title:'Mental Health Research Team Member',
            img:abdul,
            desc:<div>
                <p className={'h5 mgT2'}>Dr. Abdulmalik Jibril is a highly accomplished researcher specializing in psychiatry and mental healthcare. He joins our team with an extensive educational background, including advanced degrees in Health Planning & Management, Child and Adolescent Mental Health, and Mental Health Policy & Services from top institutions in Lisbon and Amsterdam. </p>

                <p className={'h5 mgT2'}>Throughout his career, Dr. Abdulmalik has made significant contributions to the field of mental health research. His work encompasses a wide range of topics, including mental health policy and economics, child and adolescent mental health, and implementation science for public health interventions. He has a remarkable publication record, with over 100 peer-reviewed articles published in reputable journals. His research interests also extend to areas such as leadership and management in healthcare, project management, and global mental health.</p>
                <p className={'h5 mgT2'}>Dr. Abdulmalik's expertise and dedication to advancing mental health practices extends beyond academia making him an invaluable asset to not just our team and research endeavors, but the society at large. </p>
            </div>
        },
    ])
    return {
researchers
    }
};

export default useResearchers;