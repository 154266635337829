import React from 'react';
import {Link} from "react-router-dom";
const GalleryItem = ({data}) => {
    return (
        <Link to={'/event/2'} className={'gallery-item'}>
            <div className="gallery-item-img">

            </div>
            <div className="gallery-item-text mgT2">
                <h4 className="h4 whiteText">{data?.title}</h4>
            </div>
        </Link>
    );
};

export default GalleryItem;