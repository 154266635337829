import React from 'react';
import bg from "../../assets/about.png";
import Navigation from "../../components/Navigation";
// import Hero from "../home/hero";
import Footer from "../../components/Footer";
import MissionVision from "./missionVision";
import AboutSection from "./aboutSection";
// import Partners from "../home/partners";
import TeamSection from "../home/teamSection";
import useScrollToTop from "../../helpers/UI/scrollToTop";
import {motion} from "framer-motion";

const About = () => {
    useScrollToTop();

    const transition = {
        duration:.6,
        ease: [0.43,0.13,0.23,0.96]
    }
    return (

        <motion.div initial={{opacity:0}} animate={{opacity:1}}  transition={transition} exit={{opacity:0}}>
            <div className={"hero-section"} style={{backgroundImage:`linear-gradient(333.49deg, rgba(0, 0, 0, 0.4) 35.5%, rgba(0, 0, 0, 0.23) 91.58%),url(${bg})`, minHeight:`${"50"}vh`}}>
                <Navigation/>
                <div className="hero-section-center">
                    <div className="textCenter">
                        <h1 className="h3 whiteText textShadow" style={{fontWeight:"bold"}}>ABOUT US</h1>
                    </div>
                </div>
            </div>
            <AboutSection/>
            <MissionVision/>
            <TeamSection/>
            <Footer/>
        </motion.div>
    );
};

export default About;