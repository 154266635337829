import React, {useState} from 'react';
import AdminDash from "../../../layout/admin";
import AxiosInstance from "../../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../../components/loader";

const UploadToGallery = () => {
    const [types] = useState(['video','image']);
    const [pTypes] = useState(['gallery','event']);
    const [type,setType] = useState('video');
    const [end,setEnd] = useState(Date);
    const [start,setStart] = useState(Date);
    const [pType,setPType] = useState('Gallery');
    const [title,setTitle] = useState('')
    const [description,setDescription] = useState('')
    const [images,setImages] = useState([]);
    const [files_,setFiles_] = useState([]);
    const [loading,setLoading] = useState(false);
    function upload(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title',title);
        formData.append('description',description);
        formData.append('type',type);
        formData.append('start',start);
        formData.append('end',end);
        for (let i = 0;i<files_.length;i++){
           if (files_[i].type.startsWith(`${type}/`)){

               formData.append('file',files_[i],files_[i].name)
           }else{

           }

        }
        console.log(formData.getAll('file'))

       if (title.length>0 && type.length>0 && files_.length>0){
           setLoading(true)
           AxiosInstance.post('/g/add',formData,{
               headers:{
                   authorization: localStorage.getItem('user_ton')
               }
           })
               .then(res=>{
                   setLoading(false)
                   toast(res.data.msg)
                   setTitle('');
                   setDescription('');
                   setImages([]);
                   setFiles_([])
               })
               .catch(err=>{
                   setLoading(false)
                   toast.error(err.response.msg)
               })
       }else{
           toast.info('Please add an image, title and description')
       }
    }
    function selectImage(e) {

        //change the files to dataurl and pass it to an image tag

        const files = e.target.files;
        const urlsList = [];
        // console.log(files);
         setFiles_(files)
        for (let i = 0;i<files.length;i++){
            if (files[i].type.startsWith(`${type}/`)){
                let res = getBase64(files[i])
                urlsList.push(res)
            }else{

                toast.info(`Please select ${type}s`)
            }
        }
        console.log(urlsList)
        if (!urlsList.length>0){   setFiles_([])}
        setImages(urlsList);
    }
    function getBase64(file) {

        return  window.URL.createObjectURL(file)

    }


    return (
        <AdminDash>
            <h4 className="h4 whiteText">Upload To Gallery</h4>
            <p className="h6 lightColor">Fill form to add image or video to gallery</p>
            <ToastContainer/>
            {
                loading?<Loader/>:null
            }
            <div className="mgT4 grid">
                <form onSubmit={upload} className={'grid-item2_4'}>

                    <label className="h6 lightColor">Programme Type</label>
                    <div className="form-input-box">
                        <select className="form-select" onChange={(e)=>setPType(e.target.value)}>
                            {
                                pTypes.map((e,i)=><option key={i}>{e}</option>)
                            }

                        </select>
                    </div>

                    <label className="h6 lightColor">Media Type</label>
                    <div className="form-input-box">
                    <select className="form-select" onChange={(e)=>setType(e.target.value)}>
                        {
                            types.map((e,i)=><option key={i}>{e}</option>)
                        }
                    </select>
                    </div>

                    {
                        pType==="event"?<div className={'form-group'}>
                           <div className="" style={{width:"100%"}}>
                               <label className="h6 lightColor">Start date</label>
                               <div className="form-input-box">

                                   <input value={start} onChange={(e)=>setStart(e.target.value)} type="date" placeholder={'start'} className={'form-input'}/>
                               </div>
                           </div>
                            <div className="" style={{width:"100%"}}>
                                <label className="h6 lightColor">End date</label>
                                <div className="form-input-box">
                                    <input type="date" value={end} onChange={(e)=>setEnd(e.target.value)}  placeholder={'end'} className={'form-input'}/>
                                </div>
                            </div>
                        </div>:null
                    }
                    <label className="h6 lightColor">Title</label>
                    <div className="form-input-box">
                        <input className="form-input" value={title} onChange={(e)=>setTitle(e.target.value)} placeholder={'title'}/>
                    </div>

                    <label className="h6 lightColor">Description</label>
                    <div className="form-input-box">
                        <input className="form-input" value={description} onChange={(e)=>setDescription(e.target.value)} placeholder={'Description'}/>
                    </div>


                    <label className={'h6 lightColor'}>File</label>
                    <div className="form-file centerText mgT2">

                        <input  onChange={selectImage} multiple  id='file_type_input' type='file' className='file-input'/>
                        <label className='h5 lightColor' htmlFor='file_type_input'>


                            <>
                                {
                                    images.length>0?
                                        <div className=" post_image-box">
                                            {images.length>0 && type==='image'?images.map((url,id)=> <img key={id} className='post_img'
                                                                                                alt={''} src={url}/>)
                                                :images.length>0&&type==='video'?
                                                    images.map((url,id)=> <video key={id} autoPlay className='post_img'
                                                                                src={url}/>):null

                                            }
                                        </div>

                                        :`Click to add ${type}`
                                }

                            </>
                        </label>
                    </div>
                    <button className="btn btn-sec mgT8">Upload</button>
                </form>
            </div>
        </AdminDash>
    );
};

export default UploadToGallery;