import React from 'react';
// import Hero from "../home/hero";
import bg from "../../assets/research_.png";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import ResearchCard from "./researchCard";
const Research = () => {
    return (
        <div>

            <div className={"hero-section"} style={{backgroundImage:`linear-gradient(333.49deg, rgba(0, 0, 0, 0.4) 35.5%, rgba(0, 0, 0, 0.23) 91.58%),url(${bg})`, minHeight:`${"25"}vh`}}>
                <Navigation/>
                <div className="hero-section-center">
                    <div className="textCenter">
                        <h1 className="h3 whiteText textShadow" style={{fontWeight:"bold"}}>RESEARCH</h1>
                        <p className="h6 whiteText">Get Updates on our <span className="blueText">Progress</span></p>
                    </div>

                </div>
            </div>

            <div className="research-section">
               <div className="center-all">
                   <h5 className="h5 whiteText">Top Research Topics</h5>
                   <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <ResearchCard/>
                        <ResearchCard/>
                        <ResearchCard/>
                    </div>
                    <div className="grid-item2_4">
                        <ResearchCard/>
                        <ResearchCard/>
                        <ResearchCard/>
                    </div>
                   </div>
               </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Research;