import React from 'react';

// import bg from "../../assets/hero-2.png"
import bg2 from "../../assets/hero-1.png"
import Navigation from "../../components/Navigation";
// import Moment from "react-moment";
import {Link} from "react-router-dom";
const Hero = (props) => {

    return (
        <div className={'hero-section carousel'} style={{backgroundImage:`linear-gradient(333.49deg, rgba(0, 0, 0, .32) 35.5%, rgba(0, 0, 0, .23) 91.58%), url(${bg2})`, minHeight:`${props.height?props.height:"70"}vh`}}>
            <Navigation/>
            <div className="hero-section-center">
                <div className=" grid">
                    <div className="grid-item3_4 hero-section">
                        <h1 className={'h3 whiteText display_block mgT4'}>Climate Change Awareness and Sustainable Healthcare Development Initiative </h1>

                        <p className="h5 mgT2 whiteText shadowText">Empowering Communities for a Sustainable Future:
                        Uniting Climate Action, Mental Health Awareness, and Health Sustainability</p>

                                {/*<p className="h5 mgT2 greyText  "> Join us in empowering communities</p>*/}
                        <Link to={'/contact'} className="btn btn-primary mgT4">
                            Join Us
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Hero;