import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Navigation from "../../../components/Navigation";
import AxiosInstance from "../../../helpers/axiosInstance";
// import Moment from "react-moment";
import useScrollToTop from "../../../helpers/UI/scrollToTop";
import {motion} from "framer-motion";
import Footer from "../../../components/Footer";
// const base = 'http://localhost:5000';
const base = 'https://ccshdi.com';

const Article = () => {
    const {state}  = useLocation();
    useScrollToTop()
    const [post,setPost] = useState({});
    const getLatest = useCallback(()=>{
        AxiosInstance.get(`/art/p/${state.id}`)
            .then(res=>{
                // console.log(res.data.data)
                setPost(res.data.data)
            })
            .catch(err=>{
                console.log(err)
            })
    },[setPost,state.id]);
    useEffect(()=>{
        getLatest()
    },[getLatest]);
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    return (
        <motion.div initial={{opacity:0.2,y:-100}} animate={{opacity:1,y:0}} transition={transition}>
            <div className={"article"}>
                <Navigation className={'navigation-light'}/>
                <div className="center-all grid mgT4">
                    <div className="grid-item3_4 article-left">
                        <h1 className="h4">{post?.title}</h1>
                        <p className="h6 mgT1 bold">
                            By {post?.owner?.fullName}&nbsp;
                        </p>
                        {/*<p className="h7 mgT1">*/}
                        {/*    Updated on{" "}*/}
                        {/*    <Moment format={"DD MMMM YYYY"}>{post?.updatedAt}</Moment>*/}
                        {/*</p>*/}

                        <div className="mgV2 ">
                            <img
                                src={`${base}${post?.image}`}
                                className={"fluidImg"}
                                alt=""
                            />
                        </div>
                        <div className="article-body">
                            <div
                                className={""}
                                dangerouslySetInnerHTML={{ __html: post?.description }}
                            />
                        </div>
                    </div>
                    <div className="article-right grid-item1_4 sticky">

                        <div className="mgT4 mgB4">
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </motion.div>
    );
};

export default Article;