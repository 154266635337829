import React from 'react';
import ResearcherCard from "./ResearcherCard";
import useResearchers from "./useResearchers";
import ScrollAnimation from "../../hoc/ScrollAnimation";

const ResearchersSection = () => {
   const {researchers} = useResearchers();
    return (
        <ScrollAnimation>

            <div className={'center-all width60'}>

                <h4 className="h4 centerText mgV4">Research Team</h4>
                <div className={' mgT8'}>
                    {
                        researchers.length>0?researchers.map((e,i)=><ResearcherCard key={i} data={e}/>):null
                    }
                </div>
            </div>
        </ScrollAnimation>
    );
};

export default ResearchersSection;