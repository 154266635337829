import React from 'react';
import {Link} from "react-router-dom";
import globalWarming from '../../assets/global warming.png'
import ScrollAnimation from "../../hoc/ScrollAnimation";
const GlobalWarming = () => {
    return (
       <ScrollAnimation>
           <div className={'global-warming-section'} style={{backgroundImage:`url('${globalWarming}')`}}>
               <div className="global-warming-section-center">
                   <h4 className="h4">Join the fight for a sustainable future.</h4>
                   <p className="h6 mgT2  ">Learn, act, and reduce your carbon footprint to protect our planet. Discover data-driven solutions for tackling global warming.</p>

                   <Link to={'/contact'} className="btn btn-primary mgT2">Join Us</Link>
               </div>
           </div>
       </ScrollAnimation>
    );
};

export default GlobalWarming;