import React, {useState} from 'react';
import GalleryItem from "./galleryItem";

import img from '../../assets/research_.png'
import img2 from '../../assets/about.png'

const testJson  = [
    {
        title:"The Evolution of Climate Change",
        img:img
    },
    {
        title:"The evolution of power in the lost city of wakanda",
        img:img2
    }
]
const GalleryCarousel = () => {
    const [index,setIndex] = useState(0)

    return (
        <div className={'gallery-carousel'}  style={{background:`url(${testJson[index].img})`}} >
            <div className="gallery-carousel-area">
                {
                    <GalleryItem data={testJson[index]}/>
                }
            </div>
            <div className="gallery-carousel-dots">
                {
                   testJson.length>0? testJson.map((e,i)=>  <span onClick={()=>setIndex(i)} key={i} className="gallery-carousel-dots-item"></span>):null
                }

            </div>
        </div>
    );
};

export default GalleryCarousel;