import React, {useState} from 'react';
import '../sass/components/nav.scss'
import {BiMessage, BiSearch} from "react-icons/bi";
import Logo from '../assets/logo.png'
import {Link} from "react-router-dom";

import {AiFillCloseCircle} from "react-icons/ai";
const Navigation = ({className}) => {
    const [showSearch,setShowSearch] = useState(false)
    return (
        <nav className={className?`${className} navigation`:'navigation'}>
            <div className="navigation-center">
                <figure className="logoBox">
                   <Link to={'/'} className={'no-deco'}>
                       <img src={Logo} className={'logo'} alt={'Logo'}/>
                        <span className={'h6 whiteText logo_text'} >Climate Change Awareness and Sustainable Healthcare Development Initiative</span>
                   </Link>
                </figure>
                <input type="checkbox" id="menu"/>
                <label htmlFor="menu" className={'menu'}>
                    <span className="menu-bars">&nbsp;</span>
                </label>
                <ul className="navigation-list">
                    <li className={'navigation-list-item'}>
                        <Link to={'/about'}>About</Link>
                    </li>
                    {/*<li className={'navigation-list-item'}>*/}
                    {/*    <Link to={'/gallery-and-events'}>Gallery & Events</Link>*/}
                    {/*</li>*/}
                    <li className={'navigation-list-item'}>
                        <Link to={'/researchers'}>Research Team</Link>
                    </li>
                    <li className={'navigation-list-item'}>
                        <Link to={'/news-and-reports'}>News & Reports</Link>
                    </li>
                    {/*<li className={'navigation-list-item'}>*/}
                    {/*    <Link to={'/events'}>Events</Link>*/}
                    {/*</li>*/}
                    <li className={'navigation-list-item'}>
                        <Link to={'/contact'}>Contact</Link>
                    </li>
                    {/*<li onClick={()=>setShowSearch(!showSearch)} className={'navigation-list-item'}><BiSearch /></li>*/}
                </ul>
            </div>
            {showSearch? <div className={'search_modal'}>
                 <span className="search_modal-close" onClick={()=>setShowSearch(false)}>
                     <AiFillCloseCircle />
                 </span>
                <div className="search_modal-search">
                    <input type="text" placeholder={'search'}/>
                    <BiSearch/>
                </div>

                <hr/>
                <div className="search_modal-item centerText">
                    <p className="h6 whiteText ">Nothing Found</p>
                    <BiMessage size={24} className={'whiteText mgT2'} />
                </div>
                
            </div>:null}

        </nav>
    );
};

export default Navigation;