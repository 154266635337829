import React, {useState} from 'react';
// import bg from '../../assets/contact_bg.png'
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import AxiosInstance from "../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../components/loader";
import {motion} from "framer-motion";

const Contact = () => {
    const [data,setData] = useState({
        name:"",
        email:"",
        message:""
    });
    const [loading,setLoading] = useState(false)

    function handleContact(e) {
        e.preventDefault();
       if (data.email && data.message && data.email){
           setLoading(true)
           AxiosInstance.post('/contact/add',data,(req,res)=>{

           })
               .then(res=>{
                   setData({
                       name:"",
                       email:"",
                       message:""
                   });
                   toast.success(res.data.msg);

                   setLoading(false)
               })
               .catch(
                  err=>{
                      toast.error(err.response.data.msg)

                      setLoading(false)
                  }
               )
       }
    }


    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1}}  transition={transition} exit={{opacity:0}} className={'contact-page'} >
           <div className="">
               <ToastContainer/>
               <Navigation className={'navigation-light'}/>
               {
                   loading?<Loader/>:null
               }
           </div>

            <div className="center-all">
                <h4 className="h4 width35  mgV8 ">
                    Love to hear from Us?
                    Get in Touch 👋
                </h4>
                <form onSubmit={handleContact} className="contact-form">
                    <div className="grid- mgB2 fl-c-t">
                        <div className="grid-item2_4">
                            <label htmlFor={'name'} className="h7 ">Your Name</label>
                            <div className="form-input-box ">
                                <input value={data.name || ""} onChange={e=>{
                                    let old = {...data};
                                    old.name = e.target.value;
                                    setData(old)
                                }} type="text" id={'name'} className={'form-input'} placeholder={'Your Name'}/>
                            </div>
                        </div>
                        <div className="grid-item2_4">
                            <label htmlFor={'email'} className="h7 ">Your Email</label>
                            <div className="form-input-box ">
                                <input value={data.email || ""} onChange={e=>{
                                    let old = {...data};
                                    old.email = e.target.value;
                                    setData(old)
                                }} type="text" id={'email'} className={'form-input'} placeholder={'Your Email'}/>
                            </div>
                        </div>
                    </div>
                    <div className="grid mgT4"> <div className="grid-item2_4">
                        <label htmlFor={'msg'} className="h7 ">Message</label>
                        <div className="form-input-box ">
                            <textarea value={data.message || ""} onChange={e=>{
                                let old = {...data};
                                old.message = e.target.value;
                                setData(old)
                            }}  id={'msg'} className={'form-textArea'} placeholder={'Your Message'} style={{height:"10rem"}}/>
                        </div>
                    </div>

                    </div>

                    <button className="btn btn-primary mgT2">
                        Contact Us
                    </button>
                </form>
            </div>
            <Footer/>
        </motion.div>
    );
};

export default Contact;