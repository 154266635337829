import React, { useState} from 'react';
import ReactQuill from 'react-quill';
import {toast,ToastContainer} from "react-toastify";
import 'quill/dist/quill.snow.css';
// import {UserProvider} from "../hoc/userProvider";
import AxiosInstance from "../helpers/axiosInstance";
import Loader from "./loader";
const Editor = ({title_,description_,image_,id_,owner,cb}) => {
    // const user = useContext(UserProvider) || JSON.parse(localStorage.getItem('user_id')) ;
    const [title,setTitle] = useState(title_);
    const [loading,setLoading] = useState(false);
    const [description,setDescription] = useState(description_);
    // const [images,setImages] = useState([])
    // const [image,setImage] = useState();

    let modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    let formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    function createPost(){

        const data = {
            title,
            description,
            owner
        }
       if ( title.length>0 && description.length>0){
           setLoading(true)
           AxiosInstance.post(`/art/p/${id_}/update`,data,{
               headers:{
                   authorization: localStorage.getItem('user_ton')
               }
           })
               .then(res=>{
                   setLoading(false)
                   toast(res.data.msg);
                   // setImages([]);
                   setTitle('')
                   setDescription('')
                   cb()
                   // setImage(new File([], "", undefined))
               })
               .catch(err=>{
                   setLoading(false)
                   toast.error(err.response.data.msg)
               })
       }else{
           toast.error('Post details are not properly filled, make sure to add an image and at least one paragraph')
       }
    }

    // function selectImage(e) {
    //
    //     //change the files to dataurl and pass it to an image tag
    //
    //     const files = e.target.files;
    //     setImage((files[0]));
    //     const urlsList = [];
    //     // console.log(files);
    //     for (let i = 0;i<files.length;i++){
    //         let res = getBase64(files[i])
    //         urlsList.push(res)
    //     }
    //     console.log(urlsList)
    //     setImages(urlsList);
    // }
    //
    // function getBase64(file) {
    //
    //     return  window.URL.createObjectURL(file)
    //
    // }
    return (
       <div className={'mgT4'}>
           <ToastContainer/>
           {loading?<Loader/>:null}
           <div className="width60">
               <label className="h6 whiteText">Post Title</label>
               <div className="form-input-box">
                   <input className="form-input" placeholder={'title'} value={title} onChange={(e)=>setTitle(e.target.value)}/>
               </div>
               {/*<label className={'h6'}>File</label>*/}
               {/*<div className="form-file centerText mgT2 mgB4">*/}

               {/*    <input  onChange={selectImage}   id='file_type_input' type='file' className='file-input'/>*/}
               {/*    <label className='h5' htmlFor='file_type_input'>*/}


               {/*        <>*/}
               {/*            {*/}
               {/*                images.length>0?*/}
               {/*                    <div className=" post_image-box">*/}
               {/*                        {images.length>0?images.map((url,id)=> <img key={id} className='post_img'*/}
               {/*                                                                    alt={''} src={url}/>)*/}
               {/*                            :null}*/}
               {/*                    </div>*/}

               {/*                    :`Click to add Image`*/}
               {/*            }*/}

               {/*        </>*/}
               {/*    </label>*/}
               {/*</div>*/}
           </div>
           <div style={{ width: '100%' }}>
               <ReactQuill defaultValue={description_} formats={formats} modules={modules}   className={'whiteText'}  placeholder={'Write Here'} onChange={(e)=>{setDescription(e)}} theme="snow" />
           </div>


           <button  className={'btn btn-primary mgT2'} onClick={createPost}>Edit Post</button>
       </div>
    );
};

export default Editor;