import React from 'react';
import Moment from "react-moment";
import res from '../../assets/research.png'
const ResearchCard = () => {
    return (
        <div className={'research-card grid'}>
                <div className="grid-item1_3">
                    <img src={res} alt=""/>
                </div>
            <div className="grid-item2_3">
                <h5 className="h5 whiteText">Erosion causes in
                    central Africa</h5>
                <p className="h6 whiteText mgT2">Start Date</p>
                <h6 className="h6 blueText mgT1"><Moment format={'DD MMM YYYY'}/></h6>
                <div className="research-card-progress textCenter mgT2">
                    <p className="h7 whiteText">60% Progress</p>
                    <div className="research-card-progress-bar mgT1"></div>
                </div>
            </div>
        </div>
    );
};

export default ResearchCard;