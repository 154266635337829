import React from 'react';
import {Link} from "react-router-dom";

const AboutSummary = () => {
    return (
        <div className={'about-summary'}>
            <div className={'grid center-all '}>
                <div className="grid-item3_4">
                    <p className="h6 lightColor">
                        Dedicated to advancing the field of mental health and climate change, recognizing the significant impact of both areas on individuals and communities worldwide </p>
                </div>
                <Link to={'/about'} className={'btn btn-l-blue'}>Learn More</Link>
            </div>
        </div>
    );
};

export default AboutSummary;