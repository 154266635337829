import React from 'react';
import Navigation from "../../components/Navigation";
// import AxiosInstance from "../../helpers/axiosInstance";
import {motion} from "framer-motion";
import useScrollToTop from "../../helpers/UI/scrollToTop";
import BlogSection from "../home/BlogSection";

const BlogHome = () => {
    useScrollToTop();
    const transition = {
        duration:.6,
        ease: [0.43,0.13,0.23,0.96]
    }


    return (
        <motion.div className={'overflowHidden'} initial={{opacity:0}} animate={{opacity:1}}  transition={transition} exit={{opacity:0}} >
          <motion.div className="">
              <Navigation  className={'navigation-light'}/>
          </motion.div>
           <div className="mgT4">
               <BlogSection/>
           </div>
            
        </motion.div>
    );
};

export default BlogHome;