import React from 'react';
import ResearchersSection from "../about/researchersSection";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

const Researchers = () => {
    return (
        <div>

            <Navigation className={'navigation-light'}/>
            <ResearchersSection/>
            <Footer/>
        </div>
    );
};

export default Researchers;