import React from 'react';
import {motion} from "framer-motion";

const AboutSection = () => {

    const transition = {
        duration:.6,
        ease: [0.43,0.13,0.23,0.96]
    }
    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1}}  transition={transition} exit={{opacity:0}} className={'about-section'}>
            <div className="center-all">
                <h4 className="h4">Who we are</h4>
                <div className="grid mgT2">
                <div className="grid-item2_4">

                    <p className="h6">Climate change is one of the greatest threats facing our planet, and its impact on public health is significant. <span className="blueText">CCSHDI</span> is committed to addressing these challenges by providing innovative, data-driven solutions that empower communities to take action. We believe that a collaborative approach is essential, and we work closely with partners to develop inclusive and sustainable strategies that foster resilience and promote long-term sustainability.</p>

                </div>
                <div className="grid-item2_4">

                    <p className="h6">At the heart of our work is a commitment to data-driven solutions that are grounded in research and advocacy. We recognize that climate change and health are interconnected, and our focus is on developing solutions that address both challenges in an integrated and holistic way. Our organization is committed to advancing the latest research and using it to inform our work, so that we can develop the most effective strategies and solutions.</p>

                </div></div>
            </div>
        </motion.div>
    );
};

export default AboutSection;