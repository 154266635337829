import React,{useCallback, useEffect, useState} from 'react';
// import Moment from "react-moment";
import useScrollToTop from "../../helpers/UI/scrollToTop";
import AxiosInstance from "../../helpers/axiosInstance";
import { useNavigate} from "react-router-dom";

import slugify from "slugify";
// const baseUrl = 'http://localhost:5000'
const baseUrl = 'https://ccshdi.com'
const BlogSection = ({count}) => {
    useScrollToTop();
    const [latestPost,setLatestPost] = useState({});
    const navigate = useNavigate();
    // const [numOfPages,setNumOfPages] = useState(1)
    const [loading,setLoading] = useState(false)
    const [limit] = useState(count || 10);
    const [page] =useState(1);
    const [posts,setPosts] = useState([]);
    const getLatest = useCallback(()=>{
        AxiosInstance.get('/art/p/latest/any')
            .then(res=>{
                setLatestPost(res.data.data[0])
            })
            .catch(err=>{
            })
    },[setLatestPost]);
    // console.log(numOfPages)
    // function _createPagination() {
    //     let _list = [];
    //     for (let i=1;i<=numOfPages;i++){
    //         _list.push(<span onClick={()=>getPg(i)} className={page===i?'h6 pg pg-active':'h7 pg'} key={i}>{i}</span>)
    //     }
    //     return _list;
    // }

    // function getPg(pg){
    //     setPage(pg);
    // }
    // function prev() {
    //     if (page>1){
    //         setPage(page - 1)
    //     }
    // }
    // function nextPage() {
    //     setPage(page + 1)
    // }
    const getAllPosts = useCallback(()=>{
        setLoading(true)
        AxiosInstance.get(`/art/get/all?offset=${page}&limit=${limit}`)
            .then(res=>{
                setPosts(res.data.data.docs)
                setLoading(false)
                // setNumOfPages(res.data.data.totalPages);
            })
            .catch(err=>{
                setLoading(false)
            })
    },[setPosts,page,limit]);
    useEffect(()=>{
        getLatest()
    },[getLatest])

    useEffect(()=>{
        getAllPosts();
    },[getAllPosts]);


    // const transition = {
    //     duration:.6,
    //     ease: [0.43,0.13,0.23,0.96]
    // }

    function goToPost(slug,id) {
        navigate(`/news-and-reports/${slug}`,{state:{id}});
    }

    return (
        <div className={'blog-section center-all'}>
            <h4 className="h4 mgB2">Latest</h4>
            {latestPost?.title?<div onClick={()=>goToPost(slugify(latestPost?.title),latestPost._id)} className=" blog-section-main grid">
                <div className="grid-item3_4 blog-section-main-img" style={{backgroundImage:`url(${baseUrl}${latestPost.image})`}}>
                    <img src={`${baseUrl}${latestPost.image}`} alt="" className={'fluidImg img-fit'}/>
                </div>
                <div className="grid-item2_4 blog-section-main-text">
                        <h3 className="h4">{latestPost.title}</h3>

                    <p className="h7 mgT1 blueText"> By {latestPost?.owner?.fullName}&nbsp;
                        {/*<Moment format={'DD MMMM YYYY'}>{latestPost.createdAt}</Moment>*/}
                    </p>
                    <p className="h6 mgT1 dark-text" dangerouslySetInnerHTML={{__html:latestPost?.description.slice(0,150) + "..."}}/>
                    {/*<span className="btn btn-white mgT1 toUpper">Climate</span>*/}
                </div>
            </div>:null}
            <div className="blog-section-list">
                {
                    posts.length>0?posts.map(p=>{
                        return(<div onClick={()=>goToPost(slugify(p?.title),p._id)} className=" blog-section-list-card">

                            <div className="grid-item2_4 blog-section-list-card-img" style={{backgroundImage:`url(${baseUrl}${p.image})`}}>
                                <img src={`${baseUrl}${p.image}`} alt="" className={'fluidImg img-fit'}/>
                            </div>
                            <p className="h7 mgT2 ">By Omeh</p>
                            <h6 className="h5">{p?.title}</h6>
                            <div className="display_flex mgT2">
                                <span className={'h7 '}>
                                    {/*<Moment format={'DD MMMM YYYY'}>{p?.createdAt}</Moment> {' '}&nbsp;*/}
                                </span>
                                <span className={'h7 '}>| 5 mins Read</span>
                            </div>
                        </div>)
                    }):loading?<p>Loading...</p>:<div><h5 className="h6">No Post Found</h5></div>
                }
            </div>

        </div>
    );
};

export default BlogSection;